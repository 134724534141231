<template>
  <div class="container">
    <Header />
    <div class="content w">
      <div class="hotPosition">
        <div class="bigTitle">
          <div class="leftLine"></div>
          <div class="title">热门双选</div>
          <div class="rightLine"></div>
        </div>
        <div class="DualselectList">
          <div
            class="DualselectCard"
            v-for="item in 4"
            :key="item.id"
            @click="seeInfo(item.id)"
          >
            <div class="underway" >进行中</div>
            <!-- <div class="underway" v-if="item.progress === 1">进行中</div> -->
            <!-- <div class="theEnd" v-else>已结束</div> -->
            <div class="cover"><img src="" alt="" /></div>
            <!-- <div class="cover"><img :src="item.img" alt="" /></div> -->
            <div class="DualselectInfo">
              <div class="DualselectName" :title="item.title">百日冲刺┃郑州航院2022年家庭经济困难毕业生网络专场双选会</div>
              <!-- <div class="DualselectName" :title="item.title">{{ item.title }}</div> -->
              <div class="DualselectEndTime">
                <img src="../../assets/icon-riqi.png" alt="" />
                <div>举办截止日期：2022-06-27 16:00</div>
                <!-- <div>举办截止日期：{{ item.endTime | timeData }}</div> -->
              </div>
              <div class="participation">
                <img src="../../assets/company.png" alt="" />
                <div style="margin-right: 46px">
                  参加企业
                  <span style="color: #0D9fFF">12</span>
                  <!-- <span style="color: #ff6e43">{{
                    item.countOfEnterprise
                  }}</span> -->
                  家
                </div>
                <img src="../../assets/icon-xuesheng.png" alt="" />
                <div>
                  参与学生
                  <span style="color: #0D9fFF">1688</span>
                  <!-- <span style="color: #ff6e43">{{ item.countOfStudent }}</span> -->
                  人
                </div>
              </div>
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
        <!-- <div class="noData" v-if="DualselectmeetingList.length===0">暂无数据</div> -->
        <div style="clear: both"></div>
      </div>
      <div class="hotDoubleSelect">
        <div class="bigTitle">
          <div class="leftLine"></div>
          <div class="title">更多专场</div>
          <div class="rightLine"></div>
        </div>
        <div class="DualselectList">
          <div
            class="DualselectCard"
            v-for="item in allDualselectmeetingList"
            :key="item.id"
            @click="seeInfo(item.id)"
          >
            <div class="underway" v-if="item.progress === 1">进行中</div>
            <div class="theEnd" v-else>已结束</div>
            <div class="cover"><img :src="item.img" alt="" /></div>
            <div class="DualselectInfo">
              <div class="DualselectName" :title="item.title">{{ item.title }}</div>
              <div class="DualselectEndTime">
                <img src="../../assets/icon-riqi.png" alt="" />
                <div>举办截止日期：{{ item.endTime | timeData }}</div>
              </div>
              <div class="participation">
                <img src="../../assets/company.png" alt="" />
                <div style="margin-right: 46px">
                  参加企业
                  <span style="color: #ff6e43">{{
                    item.countOfEnterprise
                  }}</span>
                  家
                </div>
                <img src="../../assets/icon-xuesheng.png" alt="" />
                <div>
                  参与学生
                  <span style="color: #ff6e43">{{ item.countOfStudent }}</span>
                  人
                </div>
              </div>
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div class="noData" v-if="allDualselectmeetingList.length===0">暂无数据</div>
      <div class="pagebtms">
        <div class="twobtn">
          <el-button type="primary" @click="perviouspage">上一页</el-button>
          <el-button type="primary" @click="nextpage">下一页</el-button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
// import { getdoubleSelections } from '@/api/self'
// vue文件中引入
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: 'Index',
  data () {
    return {
      // 字典表
      dictionaryTable: [],
      // 进行中双选会列表
      DualselectmeetingList: [],
      // 全部双选会列表
      allDualselectmeetingList: [],
      current: 1,
      size: 9,
      keyword: '',
      pages: ''
    }
  },
  components: {
    Header,
    Footer
  },
  filters: {
    timeData: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(-1, 10)
    }
  },

  methods: {
    // 点击上一页
    perviouspage () {
      var doms = document.getElementsByClassName('el-message')[0]
      if (this.current === 1 || this.current === '1') {
        if (doms === undefined) {
          this.$message({
            message: '已经是第一页了',
            type: 'warning'
          })
        }
      } else {
        this.current--
        this.getalldoubleSelections()
        setTimeout(() => {
          document.body.scrollTop = document.documentElement.scrollTop = 0
        }, 500)
      }
    },
    // 点击下一页
    nextpage () {
      var doms = document.getElementsByClassName('el-message')[0]
      if (this.current === this.pages) {
        if (doms === undefined) {
          this.$message({
            message: '已经是最后一页了',
            type: 'warning'
          })
        }
      } else {
        this.current++
        this.getalldoubleSelections()
        setTimeout(() => {
          document.body.scrollTop = document.documentElement.scrollTop = 0
        }, 500)
      }
    },
    // 查看宣讲会详情
    seeInfo (id) {
      this.$router.push({ path: '/DoubleSelectInfo', query: { id: id } })
    }
    // 获取进行中双选会
    // getdoubleSelections () {
    //   getdoubleSelections({
    //     size: this.size,
    //     current: this.current,
    //     keyword: this.keyword,
    //     jobFairType: 1
    //   })
    //     .then((res) => {
    //       if (res.code === 200 || res.code === '200') {
    //         this.DualselectmeetingList = res.data.records
    //       }
    //     })
    //     .catch(() => {})
    // },
    // 获取所有双选会
    // getalldoubleSelections () {
    //   getdoubleSelections({
    //     size: this.size,
    //     current: this.current,
    //     keyword: this.keyword
    //   })
    //     .then((res) => {
    //       if (res.code === 200 || res.code === '200') {
    //         this.allDualselectmeetingList = res.data.records
    //         this.pages = res.data.pages
    //       }
    //     })
    //     .catch(() => {})
    // }
  },
  mounted () {
    // this.getdoubleSelections()
    this.getalldoubleSelections()
  }
}
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.w{
  width: 1200px;
  margin: 0 auto;
}
.container {
  min-width: 1180px;
  background: #f2f3f5;
  .noData{
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
  }
  .search {
    background-image: url("../../assets/banner.png");
    .firstRow {
      width: 100%;
      text-align: center;
      padding: 38px 0 18px;
    }
    .secondRow {
      display: flex;
      justify-content: center;
      padding-bottom: 60px;
      .searchInput {
        text-align: center;
        .el-input {
          width: 840px;
          /deep/ input.el-input__inner {
            height: 55px;
            border: 0;
            border-radius: 5px 0 0 5px;
          }
        }
        .el-button {
          width: 129px;
          height: 55px;
          padding-bottom: 2px;
          background: #ff6436;
          border: 1px solid #ff6436;
          border-radius: 0px 4px 4px 0px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
        }
        .el-select {
          width: 115px;
          background: #fff;
          /deep/ input.el-input__inner {
            height: 55px;
            text-align: center;
            padding: 0 24px;
          }
        }

        /deep/.el-input-group__prepend {
          border-radius: 0;
        }
        /deep/ .el-input-group__append {
          width: 85px;
          height: 53px;
          background: #ff6436;
          border: #ff6436;
          color: #ffffff;
        }
      }
      .Focustips {
        display: flex;
        color: #fff;
        text-align: center;
        margin-left: 65px;
        position: relative;
        .tips {
          padding: 10px 0;
        }
        .triangle {
          width: 0;
          height: 0;
          border: 7px solid;
          position: absolute;
          left: 67px;
          top: 15px;
          border-color: transparent #fff transparent transparent;
        }
        .triangle1 {
          width: 0;
          height: 0;
          border: 7px solid;
          position: absolute;
          left: 68px;
          top: 15px;
          border-color: transparent #ff9906 transparent transparent;
        }
        .QRcode {
          width: 64px;
          height: 64px;
          border: 1px solid #fff;
          text-align: center;
          line-height: 64px;
          margin-left: 9px;
          img {
            width: 90%;
            height: 90%;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .content {
    min-height: 80vh;
    .hotPosition {
      padding-top: 105px;
      .bigTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .title {
          width: 96px;
          font-size: 24px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: #666666;
        }
        .leftLine {
          width: 500px;
          height: 2px;
          background: linear-gradient(90deg, #f4f6f8 0%, #0D9fFF 100%);
          border-radius: 4px;
          margin-right: 35px;
        }
        .rightLine {
          width: 500px;
          height: 2px;
          background: linear-gradient(90deg, #0D9fFF 0%, #f4f6f8 100%);
          border-radius: 4px;
          margin-left: 35px;
        }
      }
      .DualselectList {
        .DualselectCard {
          width: 380px;
          height: 318px;
          background: #fff;
          float: left;
          position: relative;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .underway {
            width: 64px;
            height: 25px;
            background: #0D9fFF;
            border-radius: 2px;
            font-size: 14px;
            text-align: center;
            line-height: 25px;
            color: #ffffff;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
          .theEnd {
            width: 64px;
            height: 25px;
            background: #999999;
            border-radius: 2px;
            text-align: center;
            line-height: 25px;
            color: #ffffff;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
          .cover {
            width: 100%;
            height: 210px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              border-radius: 0 0 4px 4px;
              transition: all 1s;
              &:hover {
                transform: scale(1.2);
                transition: all 1s;
              }
            }
          }
          .DualselectInfo {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            .DualselectName {
              font-size: 16px;
              color: #333333;
              margin-bottom: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .DualselectEndTime {
              display: flex;
              margin-bottom: 6px;
              font-size: 12px;
              color: #666666;
              img {
                width: 13px;
                height: 13px;
                margin-right: 8px;
              }
            }
            .participation {
              display: flex;
              font-size: 12px;
              color: #666666;
              img {
                width: 13px;
                height: 13px;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
    .hotDoubleSelect {
      .bigTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .title {
          width: 96px;
          font-size: 24px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: #666666;
        }
        .leftLine {
          width: 500px;
          height: 2px;
          background: linear-gradient(90deg, #f4f6f8 0%, #0D9fFF 100%);
          border-radius: 4px;
          margin-right: 35px;
        }
        .rightLine {
          width: 500px;
          height: 2px;
          background: linear-gradient(90deg, #0D9fFF 0%, #f4f6f8 100%);
          border-radius: 4px;
          margin-left: 35px;
        }
      }
      .DualselectList {
        .DualselectCard {
          width: 380px;
          height: 318px;
          background: #fff;
          float: left;
          position: relative;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .underway {
            width: 64px;
            height: 25px;
            background: #0D9fFF;
            border-radius: 2px;
            font-size: 14px;
            text-align: center;
            line-height: 25px;
            color: #ffffff;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
          .theEnd {
            width: 64px;
            height: 25px;
            background: #999999;
            border-radius: 2px;
            text-align: center;
            line-height: 25px;
            color: #ffffff;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
          }
          .cover {
            width: 100%;
            height: 210px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              border-radius: 0 0 4px 4px;
              transition: all 1s;
              &:hover {
                transform: scale(1.2);
                transition: all 1s;
              }
            }
          }
          .DualselectInfo {
            margin-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            .DualselectName {
              font-size: 16px;
              color: #333333;
              margin-bottom: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .DualselectEndTime {
              display: flex;
              margin-bottom: 6px;
              font-size: 12px;
              color: #666666;
              img {
                width: 13px;
                height: 13px;
                margin-right: 8px;
              }
            }
            .participation {
              display: flex;
              font-size: 12px;
              color: #666666;
              img {
                width: 13px;
                height: 13px;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
     .pagebtms {
      width: 100%;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 58px;
      .twobtn {
        .el-button {
          width: 76px;
          height: 30px;
          background-color: #f8f5f4;
          border-color: #0D9fFF;
          color: #0D9fFF;
        }
        .el-button:hover {
          background-color: #0D9fFF;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
